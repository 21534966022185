body {
  background-color: #f4fbfa !important;
}
button {
  border: none;
  background-color: unset;
  cursor: pointer;
}
*:focus {
  /* Bad */
  outline: none;
}
* {
  scrollbar-color: #009d8c #f7f7f7;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 8px; /* width vertical of the entire scrollbar */
  height: 8px; /* height horizontal of the entire scrollbar */
}
*::-webkit-scrollbar-track {
  background: #f7f7f7; /* color of the tracking area */
}
*::-webkit-scrollbar-thumb {
  background-color: #009d8c; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid #f7f7f7; /* creates padding around scroll thumb */
}
.MuiBadge-badge {
  font-family: 'Rubik', sans-serif !important;
  font-weight: bold !important;
}
